import React from "react";
import "./Equation.css";

import img3 from "../assets/images/img3.png";
import eqn1 from "../assets/images/eqn1.svg";
import eqn2 from "../assets/images/eqn2.svg";
import eqn3 from "../assets/images/eqn3.svg";
import eqn4 from "../assets/images/eqn4.svg";
import eqn5 from "../assets/images/eqn5.svg";
import eqn6 from "../assets/images/eqn6(2).svg";
import lineYellow from "../assets/images/line-yellow.svg";

import glow1 from "../assets/images/Group 1311.svg";
import glow2 from "../assets/images/Group 1312.svg";
import glow3 from "../assets/images/Group 1313.svg";
import glow4 from "../assets/images/Group 1314.svg";
import glow5 from "../assets/images/Group 1315.svg";
import glow6 from "../assets/images/Group 1316.svg";

const EqCard = ({ title, desc, img }) => {
  return (
    <div className="equation__card">
      <div>
        <p className="equation__card--textb">{title}</p>
        <p className="equation__card--textr">{desc}</p>
      </div>
      <img src={img} />
      {/* <div className='equation__card--elps1'></div> */}
      {/* <div className='equation__card--elps2'></div> */}
    </div>
  );
};

const Equation = () => {
  return (
    <div className="equation__container">
      <div className="equation">
        <div className="equation__img-container">
          <div className="eqn-ttl">
            <div className="equation__title-block">
              <p className="equation__text-why">Why</p>
              <p
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                }}
              >
                <span className="equation__text-my">my</span>
                <span className="equation__text-eqn">equation?</span>
              </p>
            </div>
            <img src={lineYellow} className="eqn--line" />
          </div>
        </div>
        <div className="equation__content-block">
          <div className="equation__card-block">
            <EqCard
              title={"Special Doubt Support"}
              desc={
                "Technical support team available for a smoother experience"
              }
              // img={eqn1}
              img={glow1}
            />
            {/* <EqCard
              title={"Personalized Programme"}
              desc={"Get one-on-one special guidance to master your skills"}
              img={glow2}
              // img={eqn2}
            /> */}
            <EqCard
              title={"Expert Mentors"}
              desc={"Mentorship | Career guidance from BIG companies "}
              img={glow3}
              // img={eqn6}
            />
          </div>
          <div className="equation__card-block">
            <EqCard
              title={"Industrial Grade Projects "}
              desc={"We offer minor projects & major project "}
              img={glow6}
              // img={eqn3}
            />
            {/* <EqCard
              title={"Live Recordings "}
              desc={"Refer to lectures with our specialised platform"}
              img={glow5}
              // img={eqn4}
            /> */}
            <EqCard
              title={"Case Studies"}
              desc={"Learn about Industrial Standards  with Case Studies"}
              img={glow4}
              // img={eqn5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equation;
