import React from 'react'
import Navbar from "./components/Navbar";
import HeroSection from './components/HeroSection';
import Mentors from './components/Mentors';
import Enroll from './components/Enroll';
import Equation from './components/Equation';
import Timeline from './components/Timeline';
import Certificates from './components/Certificates';
import Stories from './components/Stories';
import Mentees from './components/Mentees';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

const App = () => {
  return (
    <div style={{
      overflow: "hidden"
    }}>
      {/* <Navbar /> */}
      <HeroSection />
      <Mentors />
      <Enroll />
      <Equation />
      <Timeline />
      <Certificates />
      <Stories />
      <Mentees />
      <FAQ />
      <Footer />
    </div>
  )
}

export default App
