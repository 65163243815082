import React from "react";
import "./Certificates.css";

import nvidia from "../assets/images/nvidia.png";
import microsoft from "../assets/images/microsoft.png";
import digiocean from "../assets/images/digiocean.png";
import aws from "../assets/images/aws.png";
import PartnershipImg from "../assets/images/partnership-img.png";
import project from "../assets/images/project.svg";
import projectSmall from "../assets/images/projectSmall.svg";
// import bosT from "../assets/images/bosT.svg";
import bosM from "../assets/images/bosM.svg";
import bosB from "../assets/images/bosB.svg";
import { v4 as uuidv4 } from "uuid";
import cert1 from "../assets/images/cert1.png";
import cert2 from "../assets/images/cert2.png";

import Carousel from "./CertificatesCarousel";
import "react-multi-carousel/lib/styles.css";
import Card from "./CertificatesCard";

// const certs = [cert1, cert2, cert1, cert2, cert1, cert2, cert1, cert2];

const cards = [
  {
    key: uuidv4(),
    content: (
      <Card imagen="https://roboai-7abfa.web.app/static/media/cert2.f5c7e22acdb654b3b770.png" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card imagen="https://roboai-7abfa.web.app/static/media/cert1.a11f0243ceb52d438bbb.png" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card imagen="https://roboai-7abfa.web.app/static/media/cert2.f5c7e22acdb654b3b770.png" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card imagen="https://roboai-7abfa.web.app/static/media/cert2.f5c7e22acdb654b3b770.png" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card imagen="https://roboai-7abfa.web.app/static/media/cert1.a11f0243ceb52d438bbb.png" />
    ),
  },
  {
    key: uuidv4(),
    content: (
      <Card imagen="https://roboai-7abfa.web.app/static/media/cert2.f5c7e22acdb654b3b770.png" />
    ),
  },
];
const responsive = {
  a: {
    breakpoint: { max: 4000, min: 1597 },
    items: 3,
  },
  b: {
    breakpoint: { max: 1597, min: 1197 },
    items: 3,
  },
  superLargeDesktop: {
    breakpoint: { max: 1197, min: 1023 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 767, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};

const Certificates = () => {
  return (
    <div className="certificates__container">
      <div className="certificate">
        <div className="cert--img-block">
          <div className="cert-ttl-blk">
            <p className="project__title-text">Industrial</p>
            <p className="project__title-textB">Project</p>
          </div>
          {/* <img src={projectSmall} className='project__image' /> */}
          <div className="cert--image-holder">
            <div className="cert--bosT-text-main">
              <div className="cert--bosT-text">
                <p
                  className="cert--bosT-textB"
                  style={{
                    color: "#0B314F",
                  }}
                >
                  Create Your{" "}
                  <span
                    style={
                      {
                        // color: "#2E95C9"
                      }
                    }
                  >
                    SPOT
                  </span>
                </p>
                <p
                  className="cert--bosT-textR"
                  style={{
                    color: "#0B314F",
                  }}
                >
                  (Agile mobile Robot)
                </p>
              </div>
              <img src={bosB} className="cert--bosB" />
            </div>
            {/* <img src={bosT} className="cert--bosT" /> */}
            <img src={bosM} className="cert--bosM" />
          </div>
          <div className="cert--text-holder">
            <p className="project__text">
              Inspired by Boston Dynamics, mentees undertake a credit-based
              industrial major project: creating their own Agile Mobile Robot,
              SPOT. Guided by mentors, we merge cutting-edge engineering, AI,
              and real-world application.
            </p>
          </div>
        </div>
        <div className="partnership-img-item">
          <img src={PartnershipImg} className="partnership-img" />
        </div>
        <div className="cert--mesh">
          <p className="cert__title-text">
            Certificates awarded in <span>Partnership</span> with
          </p>
          <div className="cert__imgs-container">
            <div className="cert__imgs--block-item">
              <div className="cert__img-item">
                <img src={aws} />
              </div>
              <div className="cert__img-item">
                <img src={nvidia} />
              </div>
            </div>
            <div className="cert__imgs--block-item">
              <div className="cert__img-item">
                <img src={digiocean} />
              </div>
              <div className="cert__img-item">
                <img src={microsoft} />
              </div>
            </div>
          </div>
        </div>

        {/* <Carousel
            responsive={responsive}
            // centerMode={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            itemClass="slider__im"
          >
            {cards.map((item, index) => (
              <img src={item} className="cert-img" />
            ))}
          </Carousel> */}
        {/* {cards.map((item, index) => ({
            <img src={item} className="cert-img" />
          }))} */}
        {/* <Carousel
            cards={cards}
            height="100px"
            width="100%"
            offset={3}
            showArrows={false}
          /> */}
      </div>
    </div>
  );
};

export default Certificates;
