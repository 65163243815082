import React from "react";
import "./Mentors.css";
import ReactShowMoreText from "react-show-more-text";
import apple from "../assets/images/apple.svg";
import abb from "../assets/images/abb.svg";
import articulus from "../assets/images/articulus.svg";
import arko from "../assets/images/arko.svg";
import benzaleel from "../assets/images/benzaleel.svg";
import hyundai from "../assets/images/hyundai.svg";
import mohit from "../assets/images/mohit.svg";
import omnipresent from "../assets/images/omnipresent.svg";
import lineYellow from "../assets/images/line-yellow.svg";
import exlYellow from "../assets/images/exl-yellow.svg";
import aaksshImg from "../assets/images/akash-img.png";
import deepeshImg from "../assets/images/deepesh-ing.png";
import mentorbg from "../assets/images/mentorbg.svg";

const MentorCard = ({ name, text, img }) => {
  return (
    <div className="mentor-block">
      <div className="mentor__card">
        <img src={img} className="mentor__card--image" />
        <div className="mentor__card--content">
          <p className="mentor__card--name">{name}</p>
          <p className="mentor__card--desc">{text}</p>
        </div>
      </div>
    </div>
  );
};

const Mentors = () => {
  return (
    <div className="mentor__container">
      <div className="mentor">
        <div className="mentor--image-block">
          <div className="ment">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "0px",
                marginBottom: "20px",
              }}
            >
              <div className="mentor__title-bar">
                <p className="mentor__title--text">Mentors from</p>
                <img className="lineYellow-img" src={lineYellow} />
              </div>
            </div>
            <div className="mentor__image-container">
              <div className="mentors-from-images">
                <div className="mentor--image-item logo-mentor1">
                  <img src={apple} />
                </div>
                <div className="mentor--image-item logo-mentor2">
                  <img src={hyundai} />
                </div>
                <div className="mentor--image-item logo-mentor3">
                  <img src={abb} />
                </div>
              </div>
              <div className="mentors-from-images">
                <div className="mentor--image-item logo-mentor4">
                  <img src={omnipresent} />
                </div>
                <div className="mentor--image-item logo-mentor5">
                  <img src={articulus} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <div className="meet-title-block">
            <p className="meet-your-title">Meet your </p>
            <p className="mentors-text">
              <span>
                Mentors
                <img src={exlYellow} style={{ height: "54px" }} />
              </span>
            </p>
          </div>
          <div className="mentor__card-container">
            <MentorCard
              name={"Arko"}
              text={
                "Technical Head @ME,ex- Vioma Motors,Mechanical Design Expert"
              }
              img={arko}
            />
            <MentorCard
              name={"Mohit"}
              text={"CEO @JUMakerspace,-TATA power,Hardware developer"}
              img={mohit}
            />
            <MentorCard
              name={"Deepesh"}
              text={"AI & Deep Learning Engineer, Cynapto Technologies"}
              img={deepeshImg}
            />
          </div>
          <ReactShowMoreText
            //lines={2}
            more={<div className="mentor-show-more">See More</div>}
            less={<div className="mentor-show-more Less-more">See Less</div>}
            // onClick={onClick}
            // expanded={expand}
            width={"0"}
          >
            <div className="mentor__card-container less__card-container">
              <MentorCard
                name={"Akash"}
                text={"Firmware Engineer, Apple"}
                img={aaksshImg}
              />

              <MentorCard
                name={"Bezaleel"}
                text={
                  "Robotics Consultant@Prag Robotics,Ed - Specialist@Enlight Wisdom"
                }
                img={benzaleel}
              />
            </div>
          </ReactShowMoreText>
        </div>
      </div>
    </div>
  );
};

export default Mentors;
