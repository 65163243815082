import React from 'react'
import "./Timeline.css";
import Accordion from './Accordion';

import lineYellow from "../assets/images/line-yellow.svg";
import timelineIcon from "../assets/images/timelineIcon.svg";

const Timeline = () => {
    return (
        <div className='timeline__container'>
            <div className='timeline'>
                {/* <div style={{ width: "100%" }}> */}
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "15px",
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <img src={timelineIcon} className='timeline--icon' />
                        <p className='timeline__title-text'>Timeline</p>
                    </div>
                    <img src={lineYellow} style={{ width: "45%" }} className='timeline--line' />
                </div>
                <div className='timeline__accordions'>
                    <Accordion
                        title={"Mechanical & Industrial Design"}
                        options={["Robot Mathematics", "Robot Matrices", "Mechanical CAD", "Analysis and Prototyping"]}
                        index={0}
                    />
                    <Accordion
                        title={"Electronics & IOT"}
                        options={["VFD Drivers", "IOT & PWM", "PCB Designing", "Sensors Integration"]}
                        index={1}
                    />
                    <Accordion
                        title={"ML/AI & ROS-2"}
                        options={["Introduction to Pyro & Dart", "ANN, DNN & CNN", "Regression Analysis & PCA", "Gazebo & OpenCV"]}
                        index={2}
                    />
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default Timeline